/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/* Tailwinds CSS */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import url('https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&family=Russo+One&family=Ubuntu:wght@500&display=swap');

body {
  overflow-y: auto;
  overflow-x: hidden;
  font-family: 'Hind', sans-serif;
}

// BUTTONS

// Base Button Mixin
@mixin btn-base {
  @apply inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm ease-out duration-300 hover:scale-105;
}

// Primary Button Mixin
@mixin btn-primary {
  @include btn-base;
  @apply bg-red-600 text-white;

  &:hover {
    @apply bg-secondary-yellow-500;
  }

  &:focus-visible {
    @apply outline outline-2 outline-offset-2 outline-red-500;
  }
}

.primary-button {
  @include btn-primary;
}

// Secondary Button Mixin
@mixin btn-secondary {
  @include btn-base;
  @apply bg-white text-gray-900 ring-1 ring-inset ring-gray-300;

  &:hover {
    @apply bg-secondary-yellow-500 text-white;
  }
}

.secondary-button {
  @include btn-secondary;
}

//
// PAGE HEADER
//

@mixin page-header {
  @apply text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight;
}

.page-header {
  @include page-header;
}

//
// TILE MIXINS
//

@mixin tile-base {
  @apply bg-white lg:mt-10 mt-4 p-6 rounded-lg shadow;
}

@mixin tile-primary {
  @include tile-base;
}

.tile-primary {
  @include tile-primary;
}

// Tile Flex Mixin

@mixin tile-flex {
  @apply md:flex md:items-center md:justify-between;
}

.tile-flex {
  @include tile-flex;
}

// Tile Body Mixin

@mixin tile-body {
  @apply min-w-0 flex-1
}

.tile-body {
  @include tile-body;
}

//
// TEXTAREA
//

// Textarea Mixin

@mixin textarea-style {
  @apply  block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6
}

.textarea-style {
  @include textarea-style;
}

@mixin label-style {
  @apply block text-sm font-medium leading-6 text-gray-900
}

.my-label {
  @include label-style;
}

//
// PAGE SECTION
//

@mixin page-section($backgroundColor: 'bg-white') {
  // Default styles
  @apply py-24 md:py-24 bg-red-300;
  // background-image: url('assets/flex-ui-assets/elements/pattern-white.svg');
  background-position: center;

  // Conditional application of background color
  @if $backgroundColor == 'bg-white' {
    @apply bg-white;
  } @else if $backgroundColor == 'bg-gray-300' {
    @apply bg-gray-300;
  }
}

.page-section {
  @include page-section;
}

.dashboard-page-section {
  @include page-section('bg-gray-300');
}



.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.login-modal .modal-wrapper {
  height: 45%;
  top: 55%;
  position: absolute;
  display: block;
  border-radius: 20px 20px 0 0;
}

.register-modal .modal-wrapper {
  height: 50%;
  top: 50%;
  position: absolute;
  display: block;
  border-radius: 20px 20px 0 0;
}

.primary-input {
  background: aliceblue;
  --padding-start: 10px !important;
  color: #999999;
  border-radius: 5px;
  margin-bottom: 10px;
}
